:root {
  --blue: #112a52;
  --green: #13802c;
}

html {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  height: 100vh;
  background: var(--blue);
}

.wrap {
  display: grid;
  gap: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  height: 100%;
}

video {
  border-radius: 20px;
  height: 100%;
  max-width: 100%;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  border: 0.5px solid rgba(255,255,255,0.2);
  pointer-events: none;
  height: auto;
  max-height: 100vh;
}

.video {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 20px;
  align-items: center;
}

.camera {
  color: white;
  font-size: 10px;
  transition: transform 0.1s;
  position: relative;
}

.start-camera {
  background: var(--green);
  border: 2px solid transparent;
  padding: 6px;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}

.activate {
  background: var(--green);
  border: 2px solid transparent;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

button:hover {
  transform: scale(1.1)
}

.cred {
  grid-row: 2;
  padding-bottom: 20px;
}